import React from 'react';
import VMVComponent from '.';
import { VMVMedia } from 'src/assets';
import "./style.scss";

const VMData = () => {
    const tabsData = [
        {
            Statement: 'STATEMENTS',
            title: 'Mission',
            Heading: 'Mission Statement',
            VMVMedia: VMVMedia,
            Description: "Our mission is to leverage Pakistan's diverse technological assets and human capital to drive research and development excellence in defense application. By facilitating collaboration, knowledge sharing, and skill development, we aim to create a dynamic ecosystem that fuels innovation, accelerates economic growth, and enhances the Private Sector contribution in nation building.",
        },{
            Statement: 'STATEMENTS',
            title: 'Vision',
            Heading: 'Vision Statement',
            VMVMedia: VMVMedia,
            Description: 'To be the premier catalyst for technological innovation, driving positive change by synergizing technological centers and fostering collaboration with industry partners for quality solutions to our Customers',
        },{
            Statement: 'STATEMENTS',
            title: 'Values',
            Heading: 'Values Statement',
            VMVMedia: VMVMedia,
            Description: 'Value is "to leverage cutting-edge research and expertise to address the pressing technological challenges and opportunities within engineering domains, translating customer needs into innovative solutions in line with industry standards. Through collaboration, creativity, and a relentless pursuit of excellence, we strive to empower our partners and clients to thrive in an ever-evolving technological landscape',
        }
    ];
    return (
        <div>
            <VMVComponent tabs={tabsData} />
        </div>
    );
};

export default VMData;