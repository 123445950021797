import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for validation
import { Modal, Button } from 'react-bootstrap';
import { Checked } from 'src/assets';
import { Typography } from 'src/components/common/Base';
import emailjs from 'emailjs-com';
import Select from 'react-select';
import './style.scss';

// Initialize EmailJS with your user ID
emailjs.init('jPAZ-WSmDjXn96NUr');

const RequestModal = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    products: [],
    email: '',
    message: '',
    sendCopy: false,
  });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const productOptions = [
    { value: 'product1', label: 'Product 1' },
    { value: 'product2', label: 'Product 2' },
    { value: 'product3', label: 'Product 3' },
    // Add more options as needed
  ];

  useEffect(() => {
    if (show) {
      // Reset form state when modal is opened
      setFormData({ products: [], email: '', message: '', sendCopy: false });
      setErrors({});
      setIsSubmitted(false);
    }
  }, [show]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSelectChange = (selectedOptions) => {
    setFormData({ ...formData, products: selectedOptions });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      setIsSubmitted(true);

      // Send email using EmailJS
      emailjs.sendForm('service_tsp8aka', 'template_8a2enty', e.target, 'jPAZ-WSmDjXn96NUr')
        .then(() => {
          // Successful submission logic
          setFormData({ products: [], email: '', message: '', sendCopy: false });
        }, () => {
          alert('Error sending message. Please try again later.');
        });
    } else {
      setErrors(errors);
    }
  };

  const validateForm = (data) => {
    const errors = {};
    if (data.products.length === 0) {
      errors.products = 'Please select at least one product or service';
    }
    if (!data.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Email address is invalid';
    }
    if (!data.message.trim()) {
      errors.message = 'Message is required';
    }
    return errors;
  };

  return (
    <Modal className='request-modal' show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        {!isSubmitted ? (
          <h4 className='m-0'>Submit a Request for Demo</h4>
        ) : (
          <span></span>
      )}
      </Modal.Header>
      <Modal.Body>
        {!isSubmitted ? (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="products">Select our products and services<sup>*</sup>:</label>
              <Select
                id="products"
                name="products"
                value={formData.products}
                onChange={handleSelectChange}
                options={productOptions}
                isMulti
                className='form-control p-0'
              />
              {errors.products && <span className='text-danger'>{errors.products}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="email">Email<sup>*</sup>:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className='form-control'
              />
              {errors.email && <span className='text-danger'>{errors.email}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="message">Message<sup>*</sup>:</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className='form-control'
              />
              {errors.message && <span className='text-danger'>{errors.message}</span>}
            </div>
            <div className="form-group text-center">
              <Button type="submit" className="btn d-btn w-100 desktop animate__fadeInUp animate__animated animate__slow">
                <Typography variant="button-text">Submit</Typography>
              </Button>
            </div>
          </form>
        ) : (
          <div className='text-center'>
            <img className='desktop animate__fadeInUp animate__animated animate__slow' style={{ width: '200px', height: 'auto' }} src={Checked} alt="Checked" />
            <h3>Your request has been <span>Submitted!</span></h3>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

// Add PropTypes for prop validation
RequestModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default RequestModal;
