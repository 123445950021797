import React from 'react';
// import { Link } from 'react-router-dom';
// import { Typography } from 'src/components/common/Base';
// import { Typography, Accordion } from 'src/components/common/Base';
import { AboutSectionImage, } from 'src/assets';
// import { Accordion } from 'src/components/common/Base';
import { AccelerateYourBusiness, OurCustomerDefault, TwoSideFullWidthSection, OurBrand, Partner, Opportunity} from 'src/components/common/Widgets';
import ServicesListDefault from 'src/components/common/Widgets/ServiceDetails/index';
import './style.scss';

// import { PercentageText } from 'src/components/common/Widgets';
// import { BlueSection } from 'src/components/common/Layout';

const LandingPage = () => {

	// const sectionsData = [
	// 	{
	// 		// heading: 'Overview',
	// 		accordions: [
	// 			{
	// 				title: 'How is GSL unique?',
	// 				content: 'Hello World 1'
	// 			},
	// 			{
	// 				title: 'What happens if I’m not satisfied with GSL SuperProducers?',
	// 				content: 'Hello World 2'
	// 			},
	// 			{
	// 				title: 'Are there any hidden fees? ',
	// 				content: 'Hello World 3'
	// 			},
	// 			{
	// 				title: 'Geography ',
	// 				content: 'Hello World 3'
	// 			}
	// 		]
	// 	},
	// 	// {
	// 	// 	heading: 'Geography',
	// 	// 	accordions: [{ title: 'How is GSL unique?', content: 'Hello World 1' }]
	// 	// }

	// 	// Add more sections as needed
	// ];

	const twoSideSecLeftSideImg = AboutSectionImage;
	const twoSideSecBadge = "About US";
	const twoSideSecTitle = 'Our commitment to excellence and creativity is embodied in our collaborative approach';
	const twoSideSecDescription = 'As we synergize technological centers and partner with industry leaders. This collaboration ensures that we deliver quality solutions that empower our clients to thrive in an ever-evolving technological landscape. Through our relentless pursuit of innovation, we are aiming to make a significant impact across various industries, providing solutions that are not only effective but also aligned with the highest standards of quality and performance.';
	// const twoSideSecList1 = 'Revolutionise the way your business operates.';
	// const twoSideSecList2 = 'Revolutionise the way your business operates.';
	// const twoSideSecList3 = 'Revolutionise the way your business operates.';


	return (
		<>
			<div className="landing-page-wrap">
			
				<AccelerateYourBusiness />
				<Opportunity />
				<TwoSideFullWidthSection
					twoSideSecLeftSideImg={twoSideSecLeftSideImg}
					twoSideSecBadge={twoSideSecBadge}
					twoSideSecTitle={twoSideSecTitle}
					twoSideSecDescription={twoSideSecDescription}
				// twoSideSecList1={twoSideSecList1}
				// twoSideSecList2={twoSideSecList2}
				// twoSideSecList3={twoSideSecList3}
				/>
				<ServicesListDefault />

				<OurCustomerDefault />

				<OurBrand />

				{/* <OurTeam /> */}

				{/* <LogoSlider/> */}

				<Partner />
				{/* <LaunchYouBusiness /> */}

				{/*

				<div>
		
				</div>

				<section className='asked-questions ah-py-100'>
					<div className="container common-questions-container">
						<div className="row justify-content-center">
							<div className="col-md-6">
								<div className="description text-center">
									<div className="cstm-badge"><img src={RisingStars} alt="RisingStars" /><span>OUR EXPERT'S ANSWER</span></div>
									<br/>
									<h2>Commonly Asked <span>Questions</span></h2>
									<p>Montes nascetur ridiculus mus mauris vitae ultricies leo integer malesuada. Dolor purus non enim praesent elementum. Tortor posuere ac ut consequat semper viverra nam.</p>
								</div>
							</div>
							<div className="col-md-10">
								<div className="accordions" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="500">
									{sectionsData.map((section, index) => (
										<div className="accordions-containers" key={index}>
											<div className="all-accordions">
												{section.accordions.map((accordion, i) => (
													<Accordion
														key={i}
														title={accordion.title}
														content={accordion.content}
													/>
												))}
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</section> */}

			</div>
		</>
	);
};

export default LandingPage;