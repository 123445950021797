import { Link } from 'react-router-dom';
import './style.scss';
import { WhiteLogo } from 'src/assets';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
// import { Typewriter } from 'react-simple-typewriter';
import React from 'react';

const Footer = () => {
  // Define an array of primary footer links
  const UsefulFooterLinks = [
    { id: 1, title: 'Home', url: '/' },
    { id: 2, title: 'About Us', url: '/about-us' },
    { id: 3, title: 'Services', url: '/services' },
    { id: 4, title: 'Products', url: '/services' },
    { id: 6, title: 'Careers', url: '/careers' },
    { id: 6, title: 'Contact Us', url: '/contact-us' },
    // Add more primary links as needed
  ];

  const ServicesFooterLinks = [
    { id: 1, title: 'Power Electronics', url: '/Services/1' },
    { id: 2, title: 'Microwave and RF', url: '/Services/2' },
    { id: 3, title: 'Antenna Engineering', url: '/Services/3' },
    { id: 4, title: 'Embedded Software', url: '/Services/4' },
    { id: 5, title: 'Signal Processing', url: '/Services/5' },
    { id: 6, title: 'Navigation Systems', url: '/Services/6' },
    { id: 6, title: 'See More', url: '/Services' },
    // Add more primary links as needed
  ];

  // const ProductsFooterLinks = [
  //   { id: 1, title: 'Navigation Systems', url: '/Services/6' },
  //   { id: 2, title: 'M, P, H, and Control System', url: '/Services/7' },
  //   { id: 2, title: 'Equipment Maintenance', url: '/Services/8' },
  //   { id: 3, title: 'Metallurgy', url: '/Services/9' },
  //   { id: 4, title: 'System Engineering', url: '/Services/10' },
  //   // { id: 5, title: 'Client Acceptance Testing', url: '/Services/12' },
  //   // Add more primary links as needed
  // ];

  const CopyrightFooterLinks = [
    { id: 1, title: 'Terms & Conditions', url: '/' },
    { id: 2, title: 'Privacy Policy', url: '/' },
    // { id: 3, title: 'Contact Us', url: 'contact-us' },
    // Add more primary links as needed
  ];

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.3 } }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  // Intersection Observer hooks
  const { ref: contentRef, inView: contentInView } = useInView({ triggerOnce: false });

  return (
    <div className='ah-footer-new-changes'>
      <footer className="footer-section">
        <div className="container">
          <motion.div
            ref={contentRef}
            initial="hidden"
            animate={contentInView ? "visible" : "hidden"}
            variants={containerVariants}
            transition={{ duration: 0.6 }}
            className="footer-content pt-5"
          >
            <div className="row">
              <motion.div className="col-xl-3 col-lg-4 col-md-6 mb-50" variants={itemVariants}>
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link to={"/"} >
                      <img src={WhiteLogo} className="img-fluid" alt="logo" />
                    </Link>
                  </div>
                  <div className="footer-text">
                    <p>If you have any questions or need help, feel free to contact with our team.</p>
                  </div>
                  <div className='footer-contacts'>
                    <ul className='contact-info-footer'>
                      <li></li>
                      <li>
                        <h4>Send Us Email:</h4>
                        <Link to={"mailto:info@spacetimessolutions.com"}>
                          <i className="far fa-envelope-open"></i>
                          <span>info@spacetimessolutions.com</span>
                        </Link>
                      </li>
                      <li>
                        <h4>Call Us Today:</h4>
                        <Link to={"tel:92-21-35300262"}>
                          <i className="fas fa-phone"></i>
                          <span>+44 20 7123 4567</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </motion.div>
              <div className='col-md-1'></div>
                {/* <div className='row'> */}
                  <motion.div className="col-lg-2 col-md-3 col-sm-6 mb-30" variants={itemVariants}>
                    <div className="footer-widget">
                      <div className="footer-widget-heading">
                        <h3 className='text-white'>Company</h3>
                      </div>
                      <ul>
                        {UsefulFooterLinks.map(link => (
                          <li key={link.id}>
                            <Link className="subListHeading" to={link.url}>{link.title}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </motion.div>
                  <motion.div className="col-lg-2 col-md-3 col-sm-6 mb-30" variants={itemVariants}>
                    <div className="footer-widget">
                      <div className="footer-widget-heading">
                        <h3 className='text-white'>Services
                          {/* <Typewriter
                            words={['Services']}
                            loop={-1}
                            cursor
                            cursorStyle='_'
                            typeSpeed={70}
                            deleteSpeed={50}
                            delaySpeed={1500}
                          /> */}
                        </h3>
                      </div>
                      <ul>
                        {ServicesFooterLinks.map(link => (
                          <li key={link.id}>
                            <Link className="subListHeading" to={link.url}>{link.title}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </motion.div>
                  <motion.div className="col-lg-4 col-md-12 mb-30" variants={itemVariants}>
                    <div className="footer-widget">
                      <div className="footer-widget-heading">
                        <h3 className='text-white'>Subscribe to newsletter</h3>
                      </div>
                      {/* <ul>
                        {ProductsFooterLinks.map(link => (
                          <li key={link.id}>
                            <Link className="subListHeading" to={link.url}>{link.title}</Link>
                          </li>
                        ))}
                      </ul> */}
                      <p className='text-p'>Enter your email to get curated content from our industry alerts, news and insights.</p>
                      <motion.div className="mb-50" variants={itemVariants}>
                        <div className="footer-widget">
                          <div className="subscribe-form">
                            <form action="#">
                              <input type="text" placeholder="Enter your email" />
                              <button aria-label="Send"><i className="fab fa-telegram-plane"></i>Send</button>
                            </form>
                          </div>
                        </div>
                      </motion.div>
                      <div className="footer-social-icon">
                        <span>Follow us</span>
                        <Link className="subListHeading" to={`https://www.facebook.com/`} target='\blank'>
                          <i className="fab fa-facebook"></i>
                        </Link>
                        <Link className="subListHeading" to={`https://www.twitter.com/`} target='\blank'>
                          <i className="fab fa-twitter"></i>
                        </Link>
                        <Link className="subListHeading" to={`https://pk.linkedin.com/company/qvise-pvt-ltd`} target='\blank'>
                          <i className="fab fa-linkedin"></i>
                        </Link>
                      </div>
                    </div>
                  </motion.div>
                {/* </div>
              </div> */}
            </div>
            <motion.div className='col-md-12 mt-5' variants={itemVariants}>
              <div className="copyright-area">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 text-start text-lg-left">
                      <div className="copyright-text">
                        <p>© 2024 - <Link className="subListHeading" to={`/`}>Space Times Solutions ltd.</Link>All Rights Reserved.</p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 d-none d-lg-block">
                      <div className="footer-menu">
                        <ul className='text-end'>
                          {CopyrightFooterLinks.map(link => (
                            <li key={link.id}>
                              <Link className="subListHeading" to={link.url}>{link.title}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;