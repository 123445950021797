import React from 'react';
// import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Typewriter } from 'react-simple-typewriter';
import { RisingStars, GroupImage, AboutUsPageImage, AboutSTSLogo, AboutLineImage, ServiceBg, AboutPageBg } from 'src/assets';
import { InnerPageBanner, OurTeam, VMData } from 'src/components/common/Widgets';
import { SlickSlider } from 'src/components/common/Base';
import './style.scss';

// Slider settings
const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1024, 
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                speed: 200,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const AboutUsDefault = () => {
    // Intersection Observer hooks
    const { ref: aboutUsRef, inView: aboutUsInView } = useInView({
        triggerOnce: false, // Trigger the animation every time the section is in view
        threshold: 0.1, // Adjust this value to change when the animation triggers
    });

    const { ref: companyHistoryRef, inView: companyHistoryInView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    // Animation variants
    const variants = {
        hidden: { opacity: 0, y: 30 },
        visible: { opacity: 1, y: 0 },
    };

    // Animation transition
    const transition = { duration: 1, ease: 'easeOut' };

    return (
        <>
            <div>
                <InnerPageBanner backgroundImage={GroupImage} title="About Us" />
            </div>

            <section className='aboutUs-page-sec' ref={aboutUsRef}>
                <div className='shadeAnimation'>
                    <img src={AboutLineImage} className="line1" alt="Animation" />
                </div>
                <div className="custom-container">
                    <div className="row">
                        <div className="col-md-12 col-lg-5 mb-lg-0 mb-5">
                            <motion.div
                                className='media'
                                initial="hidden"
                                animate={aboutUsInView ? "visible" : "hidden"}
                                variants={variants}
                                transition={{ ...transition, delay: 0.2 }}
                            >
                                <img src={AboutUsPageImage} className="img-fluid" alt="logo" />
                                <img src={AboutSTSLogo} className="media-logo" alt="logo" />
                            </motion.div>
                        </div>
                        <div className='col-md-12 col-lg-7'>
                            <motion.div
                                className="description"
                                initial="hidden"
                                animate={aboutUsInView ? "visible" : "hidden"}
                                variants={variants}
                                transition={{ ...transition, delay: 0.4 }}
                            >
                                <div className="cstm-badge">
                                    <img src={RisingStars} alt="logo" />
                                    <span>
                                        <Typewriter
                                            words={['Introduction']}
                                            loop={-1}
                                            cursor
                                            cursorStyle='_'
                                            typeSpeed={70}
                                            deleteSpeed={50}
                                            delaySpeed={1000}
                                        />
                                    </span>
                                </div>
                                <div className='w-100'></div>
                                <h2>Space Times Solutions is at the forefront of <span>technological innovation</span></h2>
                                <p>Dedicated to addressing the complex challenges in the engineering domain. By leveraging cutting-edge research and unparalleled expertise, we transform our customer needs into industry-standard solutions that drive progress and foster sustainable growth of your Company.</p>
                                <p>Our commitment to excellence and creativity is embodied in our collaborative approach, as we synergize technological centers and partner with industry leaders. This collaboration ensures that we deliver quality solutions that empower our clients to thrive in an ever-evolving technological landscape. Through our relentless pursuit of innovation, we are aiming to make a significant impact across various industries, providing solutions that are not only effective but also aligned with the highest standards of quality and performance.</p>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='company-history bg-light' id='company-history' style={{ backgroundImage: `url(${ServiceBg})` }} ref={companyHistoryRef}>
                <div className="custom-container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-12">
                            <motion.div
                                className='description'
                                initial="hidden"
                                animate={companyHistoryInView ? "visible" : "hidden"}
                                variants={variants}
                                transition={{ ...transition, delay: 0.2 }}
                            >
                                <div className="cstm-badge">
                                    <img src={RisingStars} alt="logo" />
                                    <span>
                                        <Typewriter
                                            words={['COMPANY HISTORY']}
                                            loop={-1}
                                            cursor
                                            cursorStyle='_'
                                            typeSpeed={70}
                                            deleteSpeed={50}
                                            delaySpeed={1000}
                                        />
                                    </span>
                                </div>
                                <br />
                                <h2 className='theme-h-color-white'>Founding <span>Story</span></h2>
                            </motion.div>
                        </div>
                        <div className='row justify-content-between align-items-center'>
                            <div className="col-md-6 pe-md-5">
                                <motion.p
                                    className=''
                                    initial="hidden"
                                    animate={companyHistoryInView ? "visible" : "hidden"}
                                    variants={variants}
                                    transition={{ ...transition, delay: 0.4 }}
                                >
                                    Space Times Solutions was founded in 2016 with a primary focus on trading. Recognizing the evolving demands of the industry, we transitioned to providing customized technical and engineering solutions. Our journey from a trading business to a leading provider of innovative engineering solutions showcases our adaptability and commitment to meeting the pressing needs of our clients.
                                </motion.p>
                            </div>
                            <div className="col-md-6 ps-md-5">
                                <motion.p
                                    className=''
                                    initial="hidden"
                                    animate={companyHistoryInView ? "visible" : "hidden"}
                                    variants={variants}
                                    transition={{ ...transition, delay: 0.4 }}
                                >
                                    Our initial years were marked by a dedication to understanding and addressing the specific challenges faced by our clients. This led to a gradual shift towards offering tailored technical and engineering solutions that not only met but exceeded industry standards. Our expertise and relentless pursuit of excellence have allowed us to carve a niche in the dynamic world of engineering.
                                </motion.p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <motion.div
                                className='company-history-slider'
                                initial="hidden"
                                animate={companyHistoryInView ? "visible" : "hidden"}
                                variants={variants}
                                transition={{ ...transition, delay: 0.6 }}
                            >
                                <SlickSlider settings={settings}>
                                    <div className="history-slide">
                                        <h3>2016</h3>
                                        <span className='for-round'></span>
                                        <p>Founded as a trading business.</p>
                                    </div>
                                    <div className="history-slide">
                                        <h3>2017</h3>
                                        <span className='for-round'></span>
                                        <p>Expanded services to include basic technical solutions.</p>
                                    </div>
                                    <div className="history-slide">
                                        <h3>2018</h3>
                                        <span className='for-round'></span>
                                        <p>Began offering customized engineering solutions</p>
                                    </div>
                                    <div className="history-slide">
                                        <h3>2019</h3>
                                        <span className='for-round'></span>
                                        <p>Expanded engineering solutions</p>
                                    </div>
                                    <div className="history-slide">
                                        <h3>2020</h3>
                                        <span className='for-round'></span>
                                        <p>Enhanced service offerings</p>
                                    </div>
                                    <div className="history-slide">
                                        <h3>2021</h3>
                                        <span className='for-round'></span>
                                        <p>Introduced new technological advancements</p>
                                    </div>
                                    <div className="history-slide">
                                        <h3>2022</h3>
                                        <span className='for-round'></span>
                                        <p>Strengthened industry partnerships</p>
                                    </div>
                                    <div className="history-slide">
                                        <h3>2023</h3>
                                        <span className='for-round'></span>
                                        <p>Expanded global presence</p>
                                    </div>
                                    <div className="history-slide">
                                        <h3>2024</h3>
                                        <span className='for-round'></span>
                                        <p>Further innovation and growth</p>
                                    </div>
                                </SlickSlider>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </section>

            <div className='out-team-vmdata-sec' style={{ backgroundImage: `url(${AboutPageBg})` }}>
                <OurTeam />
                <VMData />
            </div>
        </>
    );
};

export default AboutUsDefault;
