import React from 'react';
import { ChevronUp, ChevronDown } from 'react-feather';
import PropTypes from 'prop-types';
import './styles.scss'; // Ensure you have the correct path
import { Typography } from '..';

const Accordion = ({ title, content, isOpen, onClick }) => {
	const handleKeyPress = (event) => {
		if (event.key === 'Enter' || event.key === ' ') {
			onClick();
		}
	};

	return (
		<div className={`accordion-container ${isOpen ? 'active' : ''}`}>
			<div
				className="title-icon"
				onClick={onClick}
				onKeyPress={handleKeyPress}
				role="button"
				tabIndex="0"
			>
				<Typography variant="body-2" className="accordion-title">
					{title}
				</Typography>
				<div className="arrow-icon">{isOpen ? <ChevronUp /> : <ChevronDown />}</div>
			</div>
			{isOpen && (
				<Typography className="content" variant="body-1">
					{content}
				</Typography>
			)}
		</div>
	);
};

Accordion.propTypes = {
	title: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired,
};

export default Accordion;
