import React from 'react';
import { BrandIcon1, BrandIcon2, BrandIcon3, BrandIcon4, Product, RisingStars, BrandMedia1, BrandMedia2, ServiceBg } from 'src/assets';
import { Typography, Button } from 'src/components/common/Base';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Typewriter } from 'react-simple-typewriter';
import './style.scss';

const OurBrand = () => {
    const { ref, inView } = useInView({
        triggerOnce: false, // Animation will trigger every time it comes into view
        threshold: 0.1, // Animation triggers when 10% of the section is in view
    });

    const animationVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
    };

    const hoverEffect = {
        scale: 1.05,
    };

    return (
        <>
            <section className='ourBrand-sec bg-light' id='ourBrand-sec' style={{ backgroundImage: `url(${ServiceBg})` }}>
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-7 col-lg-6">
                            <div className="ourBrandMedia">
                                <motion.img
                                    className="desktop img-fluid ourBrandMediaLeft"
                                    src={BrandMedia2}
                                    alt="Product"
                                    initial="hidden"
                                    animate={inView ? "visible" : "hidden"}
                                    variants={animationVariants}
                                    transition={{ duration: 0.6 }}
                                    whileHover={hoverEffect}
                                    ref={ref}
                                />
                                <motion.img
                                    className="desktop img-fluid ourBrandMediaMain"
                                    src={Product}
                                    alt="Product"
                                    initial="hidden"
                                    animate={inView ? "visible" : "hidden"}
                                    variants={animationVariants}
                                    transition={{ duration: 0.6, delay: 0.2 }}
                                    whileHover={hoverEffect}
                                />
                                <motion.img
                                    className="desktop img-fluid ourBrandMediaRight"
                                    src={BrandMedia1}
                                    alt="Product"
                                    initial="hidden"
                                    animate={inView ? "visible" : "hidden"}
                                    variants={animationVariants}
                                    transition={{ duration: 0.6, delay: 0.4 }}
                                    whileHover={hoverEffect}
                                />
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <motion.div
                                className="cstm-badge"
                                initial="hidden"
                                animate={inView ? "visible" : "hidden"}
                                variants={animationVariants}
                                transition={{ duration: 0.6 }}
                            >
                                <img src={RisingStars} alt="RisingStars" />
                                <span>
                                    <Typewriter
                                        words={['Our FUTURE OUTLOOK']}
                                        loop={-1}
                                        cursor
                                        cursorStyle='_'
                                        typeSpeed={70}
                                        deleteSpeed={50}
                                        delaySpeed={1000}
                                    />
                                </span>
                            </motion.div>
                            <br />
                            <motion.h2
                                className='theme-h-color-white'
                                initial="hidden"
                                animate={inView ? "visible" : "hidden"}
                                variants={animationVariants}
                                transition={{ duration: 0.6, delay: 0.2 }}
                            >
                                With a <span>renewed look</span> and ambitious goals
                            </motion.h2>
                            <motion.p
                                initial="hidden"
                                animate={inView ? "visible" : "hidden"}
                                variants={animationVariants}
                                transition={{ duration: 0.6, delay: 0.4 }}
                            >
                                Space Times Solutions is committed to delivering challenging engineering solutions in an ever-changing global landscape. Our focus remains on leveraging innovation, fostering collaboration, and maintaining the highest quality standards to empower our clients and partners.
                            </motion.p>
                            {/* <motion.p
                                initial="hidden"
                                animate={inView ? "visible" : "hidden"}
                                variants={animationVariants}
                                transition={{ duration: 0.6, delay: 0.4 }}
                            >
                                At Space Times Solutions, we pride ourselves on having a diverse and highly skilled team that is the backbone of our success. Our team is composed of experienced engineers, seasoned design professionals, accomplished project managers, and ambitious fresh graduates, all working together to drive innovation and excellence.
                            </motion.p> */}
                            <div className="row">
                                <div className="col-md-6">
                                    <motion.figure
                                        className='ourBrand-services'
                                        initial="hidden"
                                        animate={inView ? "visible" : "hidden"}
                                        variants={animationVariants}
                                        transition={{ duration: 0.4, delay: 0.6 }}
                                        whileHover={hoverEffect}
                                    >
                                        <img className="desktop img-fluid" style={{ width: '65px', height: '58px' }} src={BrandIcon1} alt="BrandIcon1" />
                                        <figcaption>
                                            <h4>Goal Enhancements</h4>
                                            <p>Orci eu lobortis elementum nibh tellus molestie blandit elementum.</p>
                                        </figcaption>
                                    </motion.figure>
                                </div>
                                <div className="col-md-6">
                                    <motion.figure
                                        className='ourBrand-services'
                                        initial="hidden"
                                        animate={inView ? "visible" : "hidden"}
                                        variants={animationVariants}
                                        transition={{ duration: 0.4, delay: 0.8 }}
                                        whileHover={hoverEffect}
                                    >
                                        <img className="desktop img-fluid" style={{ width: '65px', height: '58px' }} src={BrandIcon2} alt="BrandIcon2" />
                                        <figcaption>
                                            <h4>Advanced Tools</h4>
                                            <p>Empowering your journey with cutting-edge tools for unparalleled efficiency and innovation</p>
                                        </figcaption>
                                    </motion.figure>
                                </div>
                                <div className="col-md-6">
                                    <motion.figure
                                        className='ourBrand-services'
                                        initial="hidden"
                                        animate={inView ? "visible" : "hidden"}
                                        variants={animationVariants}
                                        transition={{ duration: 0.4, delay: 0.0 }}
                                        whileHover={hoverEffect}
                                    >
                                        <img className="desktop img-fluid" style={{ width: '65px', height: '58px' }} src={BrandIcon3} alt="BrandIcon3" />
                                        <figcaption>
                                            <h4>Safe Transaction</h4>
                                            <p>Faucibus scelerisque eleifend donec pretium vulputate sapien.</p>
                                        </figcaption>
                                    </motion.figure>
                                </div>
                                <div className="col-md-6">
                                    <motion.figure
                                        className='ourBrand-services'
                                        initial="hidden"
                                        animate={inView ? "visible" : "hidden"}
                                        variants={animationVariants}
                                        transition={{ duration: 0.4, delay: 0.2 }}
                                        whileHover={hoverEffect}
                                    >
                                        <img className="desktop img-fluid" style={{ width: '65px', height: '58px' }} src={BrandIcon4} alt="BrandIcon4" />
                                        <figcaption>
                                            <h4>Tailored Optimization</h4>
                                            <p>Eu scelerisque felis imperdiet proin fermentum leo.</p>
                                        </figcaption>
                                    </motion.figure>
                                </div>
                                <div className="col-12 mt-4">
                                    <motion.div
                                        initial="hidden"
                                        animate={inView ? "visible" : "hidden"}
                                        variants={animationVariants}
                                        transition={{ duration: 0.6, delay: 1.4 }}
                                    >
                                        <Button className="btn primary m-auto desktop" color={'link'} variant={'solid'}>
                                            <Typography variant="button-text">See More</Typography>
                                        </Button>
                                    </motion.div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default OurBrand;
