import React from 'react';
// import { Link } from 'react-router-dom';
import { RisingStars, Client1, Client2, Client3, Client4, Client5, Client6, Client7, } from 'src/assets';
// import { Typography, Button } from 'src/components/common/Base';
import { Typewriter } from 'react-simple-typewriter';
import './style.scss';

const Partner = () => {
    // const [partnerIndex, setPartnerIndex] = useState(0);

    // List of partner images
    const partnerImages = [
        Client1,
        Client2,
        Client3,
        Client4,
        Client5,
        Client6,
        Client7,
        Client1,
        Client2,
        Client3,
        Client4,
        Client5,
        Client6,
        Client7,
        // Add more image URLs as needed
    ];
    // List of Clients images
    // const clientImages = [
    //     Client8,
    //     Client9,
    //     Client10,
    //     Client11,
    //     Client12,
    //     Client13,
    //     Client8,
    //     Client9,
    //     Client10,
    //     Client11,
    //     Client12,
    //     Client13,
    //     // Add more image URLs as needed
    // ];

    return (
        <>
            <section className='partner-sec text-center' id='partner-sec'>
            {/* <div className='bg'>
                <img className='bg-1' src={BgDotsAboutPage} alt="Background" />
                <img className='bg-2' src={BgDotsBottom} alt="Background" />
            </div> */}
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-10">
                            <div className='description'>
                                <div className="cstm-badge"><img src={RisingStars} alt="RisingStars" /><span>
                                    <Typewriter
                                        words={['Our Partners']}
                                        loop={-1}
                                        cursor
                                        cursorStyle='_'
                                        typeSpeed={70}
                                        deleteSpeed={50}
                                        delaySpeed={1000}
                                    />
                                </span></div>
                                <br />
                                <h2>We Have More Collaborating With Some Of The Most Prestigious And <span>Successful Companies In </span> The World</h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className='ah-marque-row'>
                                <div className='ah-white-space left'></div>
                                <div className='ah-content-area'>
                                    <div className='ah-animation-main-marque left-to-right' style={{ width: '100%' }}>
                                        {/* Map through partner images */}
                                        {partnerImages.map((image, index) => (
                                            <img key={index} src={image} alt={`Partner ${index}`} />
                                        ))}
                                    </div>
                                </div>
                                <div className='ah-white-space right'></div>
                            </div>
                        </div>
                        {/* <div className="col-md-8">
                            <div className='ah-marque-row'>
                                <div className='ah-white-space left'></div>
                                <div className='ah-content-area'>
                                    <div className='ah-animation-main-marque right-to-left' style={{ width: '80%' }}>
                                        {clientImages.map((image, index) => (
                                            <img key={index} src={image} alt={`Partner ${index}`} />
                                        ))}
                                    </div>
                                </div>
                                <div className='ah-white-space right'></div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Partner;
