import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MainLogo, MainLogoBlack, toggle, crossIcon } from 'src/assets'; // Add the cross icon to your imports
import { Button, Typography } from '../../Base';
import './style.scss';
import PropTypes from 'prop-types';
import { RequestModal } from '../../Widgets';

const Navbar = ({ isComingSoon }) => {
    const [isSticky, setIsSticky] = useState(false);
    const [show, setShow] = useState(false);
    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true); // Controls the collapse/expand state
    const [lastScrollY, setLastScrollY] = useState(0); // To track scroll position
    const location = useLocation();
    const navbarRef = useRef(null); // Create a ref for the navbar

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            setIsSticky(offset >= 200); // Add sticky class when scrolled past 200px

            // Auto close the navbar on scroll up
            if (offset > lastScrollY && !isNavbarCollapsed) {
                setIsNavbarCollapsed(true);
            }
            setLastScrollY(offset);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY, isNavbarCollapsed]); // Include scroll position and navbar state in the dependencies

    const toggleNavbar = () => {
        setIsNavbarCollapsed(!isNavbarCollapsed); // Toggle collapse state
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Function to close navbar if clicked outside
    const handleClickOutside = (event) => {
        if (navbarRef.current && !navbarRef.current.contains(event.target)) {
            setIsNavbarCollapsed(true);
        }
    };

    useEffect(() => {
        // Add event listener for clicks outside the navbar
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Clean up event listener on component unmount
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Auto close navbar on route change
    useEffect(() => {
        setIsNavbarCollapsed(true);  // Close the navbar on route change
    }, [location.pathname]);

    return (
        <>
            <nav
                ref={navbarRef} // Attach ref to the navbar
                className={`navbar navbar-expand-lg bg-body-tertiary ${isSticky ? 'sticky' : ''}`}
            >
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src={MainLogo} alt="WhiteLogo" width={150} className="for-stuck-nav img-fluid logo me-3" />
                        <img src={MainLogoBlack} alt="logo" width={150} className="for-sticky-nav img-fluid logo me-3" />
                    </Link>
                    <button
                        className={`navbar-toggler ${isComingSoon ? 'invisible' : ''}`}
                        type="button"
                        aria-controls="navbarSupportedContent"
                        aria-expanded={!isNavbarCollapsed}
                        aria-label="Toggle navigation"
                        onClick={toggleNavbar}
                    >
                        <img src={isNavbarCollapsed ? toggle : crossIcon} alt="toggle" /> {/* Change image based on collapse state */}
                    </button>

                    <div
                        className={`collapse navbar-collapse ${isNavbarCollapsed ? '' : 'show'} ${isComingSoon ? 'invisible' : ''}`}
                        id="navbarSupportedContent"
                    >
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="/">
                                    <Typography variant="small-text">Home</Typography>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/about-us' ? 'active' : ''}`} to="/about-us">
                                    <Typography variant="small-text">About Us</Typography>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/Services' ? 'active' : ''}`} to="/Services">
                                    <Typography variant="small-text">Products & Services</Typography>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/contact-us' ? 'active' : ''}`} to="/contact-us">
                                    <Typography variant="small-text">Contact Us</Typography>
                                </Link>
                            </li>
                            <li className="nav-item text-center d-lg-none d-block">
                                <Link className={`nav-link ${location.pathname === '/requestADemo' ? 'active' : ''}`} onClick={handleShow}>
                                    <Typography variant="small-text">Request a Demo</Typography>
                                </Link>
                                
                            </li>
                        </ul>
                    </div>
                    <div className="signup">
                        <Button className="btn d-btn" color="primary" onClick={handleShow}>
                            <Typography variant="button-text">Request a Demo</Typography>
                        </Button>
                    </div>
                </div>
            </nav>

            <RequestModal show={show} handleClose={handleClose} />
        </>
    );
};

Navbar.propTypes = {
    isComingSoon: PropTypes.bool,
};

export default Navbar;
