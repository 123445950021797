import './style.scss';
// servicesData.js
import { AntennaEngineering, ClientAcceptanceTesting, EmbeddedSoftware, EquipmentMaintenance, ESSandEMITesting, Metallurgy, MicrowaveAndRF, MPHandControlSystems, NavigationSystems, powerElectronic, SignalProcessing, SystemEngineering, powerElectronicDetail,powerElectronicDetail1, powerElectronicDetail2 } from 'src/assets';

export const Services = [
  {
    id: 1,
    icon: powerElectronic,
    servicesBg: powerElectronicDetail,
    bgImage: powerElectronicDetail,
    image: powerElectronicDetail,
    title: 'Power Electronics',
    description: 'We design and develop power electronic systems that ensure efficient and reliable power management solutions.',
    headingOne: 'We design and develop power electronic systems',
    descriptionOneShort: 'We design and develop power electronic systems that ensure efficient and reliable power management solutions. Our expertise encompasses everything from power converters to advanced energy systems, catering to diverse industry needs.',
    descriptionOneLong: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    headingTwo: 'Where can I get some?',
    descriptionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    descriptionSectionOneMedia: powerElectronicDetail1,
    descriptionSectionOne: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
    descriptionSectionTwoMedia: powerElectronicDetail2,
    descriptionSectionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
  },
  {
    id: 2,
    icon: MicrowaveAndRF,
    servicesBg: MicrowaveAndRF,
    bgImage: MicrowaveAndRF,
    image: MicrowaveAndRF,
    title: 'Microwave and RF',
    description: 'Our team excels in microwave and RF engineering, offering solutions that encompass everything from high-frequency circuits to sophisticated communication systems. We ensure optimal performance and precision in every design.',
    headingOne: 'We design and develop power electronic systems',
    descriptionOneShort: 'We design and develop power electronic systems that ensure efficient and reliable power management solutions. Our expertise encompasses everything from power converters to advanced energy systems, catering to diverse industry needs.',
    descriptionOneLong: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    headingTwo: 'Where can I get some?',
    descriptionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    descriptionSectionOneMedia: powerElectronicDetail,
    descriptionSectionOne: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
    descriptionSectionTwoMedia: powerElectronicDetail,
    descriptionSectionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
  },
  {
    id: 3,
    icon: AntennaEngineering,
    servicesBg: AntennaEngineering,
    bgImage: AntennaEngineering,
    image: AntennaEngineering,
    title: 'Antenna Engineering',
    description: 'We provide cutting-edge antenna solutions that are crucial for modern communication and Radar systems. Our designs are tailored to achieve superior performance and meet stringent industry standards.',
    headingOne: 'We design and develop power electronic systems',
    descriptionOneShort: 'We design and develop power electronic systems that ensure efficient and reliable power management solutions. Our expertise encompasses everything from power converters to advanced energy systems, catering to diverse industry needs.',
    descriptionOneLong: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    headingTwo: 'Where can I get some?',
    descriptionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    descriptionSectionOneMedia: powerElectronicDetail,
    descriptionSectionOne: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
    descriptionSectionTwoMedia: powerElectronicDetail,
    descriptionSectionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
  },
  {
    id: 4,
    icon: EmbeddedSoftware,
    servicesBg: EmbeddedSoftware,
    bgImage: EmbeddedSoftware,
    image: EmbeddedSoftware,
    title: 'Embedded Software',
    description: 'Our embedded software solutions are crafted to enhance the functionality and performance of hardware systems. We develop robust, efficient, and scalable software that seamlessly integrates with various applications.',
    headingOne: 'We design and develop power electronic systems',
    descriptionOneShort: 'We design and develop power electronic systems that ensure efficient and reliable power management solutions. Our expertise encompasses everything from power converters to advanced energy systems, catering to diverse industry needs.',
    descriptionOneLong: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    headingTwo: 'Where can I get some?',
    descriptionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    descriptionSectionOneMedia: powerElectronicDetail,
    descriptionSectionOne: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
    descriptionSectionTwoMedia: powerElectronicDetail,
    descriptionSectionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
  },
  {
    id: 5,
    icon: SignalProcessing,
    servicesBg: SignalProcessing,
    bgImage: SignalProcessing,
    image: SignalProcessing,
    title: 'Signal Processing',
    description: 'We specialize in advanced signal processing techniques, enabling us to deliver solutions that handle complex data and enhance the performance of communication, navigation, and sensor systems.',
    headingOne: 'We design and develop power electronic systems',
    descriptionOneShort: 'We design and develop power electronic systems that ensure efficient and reliable power management solutions. Our expertise encompasses everything from power converters to advanced energy systems, catering to diverse industry needs.',
    descriptionOneLong: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    headingTwo: 'Where can I get some?',
    descriptionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    descriptionSectionOneMedia: powerElectronicDetail,
    descriptionSectionOne: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
    descriptionSectionTwoMedia: powerElectronicDetail,
    descriptionSectionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
  },
  {
    id: 6,
    icon: NavigationSystems,
    servicesBg: NavigationSystems,
    bgImage: NavigationSystems,
    image: NavigationSystems,
    title: 'Navigation Systems',
    description: 'Our expertise in navigation systems ensures accurate and reliable positioning solutions for a wide range of applications, from defense to commercial sectors.',
    headingOne: 'We design and develop power electronic systems',
    descriptionOneShort: 'We design and develop power electronic systems that ensure efficient and reliable power management solutions. Our expertise encompasses everything from power converters to advanced energy systems, catering to diverse industry needs.',
    descriptionOneLong: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    headingTwo: 'Where can I get some?',
    descriptionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    descriptionSectionOneMedia: powerElectronicDetail,
    descriptionSectionOne: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
    descriptionSectionTwoMedia: powerElectronicDetail,
    descriptionSectionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
  }, {
    id: 7,
    icon: MPHandControlSystems,
    servicesBg: MPHandControlSystems,
    bgImage: MPHandControlSystems,
    image: MPHandControlSystems,
    title: 'M, P, H, and Control System',
    description: 'We provide comprehensive solutions in mechanical, pneumatic, hydraulic, and control systems, ensuring that our designs meet the highest standards of performance and reliability. Our holistic approach to system engineering ensures seamless integration and optimal functionality.',
    headingOne: 'We design and develop power electronic systems',
    descriptionOneShort: 'We design and develop power electronic systems that ensure efficient and reliable power management solutions. Our expertise encompasses everything from power converters to advanced energy systems, catering to diverse industry needs.',
    descriptionOneLong: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    headingTwo: 'Where can I get some?',
    descriptionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    descriptionSectionOneMedia: powerElectronicDetail,
    descriptionSectionOne: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
    descriptionSectionTwoMedia: powerElectronicDetail,
    descriptionSectionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
  }, {
    id: 8,
    icon: EquipmentMaintenance,
    servicesBg: EquipmentMaintenance,
    bgImage: EquipmentMaintenance,
    image: EquipmentMaintenance,
    title: 'Equipment Maintenance',
    description: 'We offer specialized services in equipment maintenance to ensure the longevity and optimal performance of critical systems. Our maintenance solutions are designed to minimize downtime and enhance the operational efficiency of industrial and technological equipment.',
    headingOne: 'We design and develop power electronic systems',
    descriptionOneShort: 'We design and develop power electronic systems that ensure efficient and reliable power management solutions. Our expertise encompasses everything from power converters to advanced energy systems, catering to diverse industry needs.',
    descriptionOneLong: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    headingTwo: 'Where can I get some?',
    descriptionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    descriptionSectionOneMedia: powerElectronicDetail,
    descriptionSectionOne: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
    descriptionSectionTwoMedia: powerElectronicDetail,
    descriptionSectionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
  }, {
    id: 9,
    icon: Metallurgy,
    servicesBg: Metallurgy,
    bgImage: Metallurgy,
    image: Metallurgy,
    title: 'Metallurgy',
    description: 'Our expertise in metallurgy allows us to provide innovative solutions for material selection, processing, and testing. We address the challenges of modern engineering by delivering materials that meet the highest standards of strength, durability, and performance.',
    headingOne: 'We design and develop power electronic systems',
    descriptionOneShort: 'We design and develop power electronic systems that ensure efficient and reliable power management solutions. Our expertise encompasses everything from power converters to advanced energy systems, catering to diverse industry needs.',
    descriptionOneLong: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    headingTwo: 'Where can I get some?',
    descriptionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    descriptionSectionOneMedia: powerElectronicDetail,
    descriptionSectionOne: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
    descriptionSectionTwoMedia: powerElectronicDetail,
    descriptionSectionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
  }, {
    id: 10,
    icon: SystemEngineering,
    servicesBg: SystemEngineering,
    bgImage: SystemEngineering,
    image: SystemEngineering,
    title: 'System Engineering',
    description: 'Under the ambit of system engineering, we offer end-to-end solutions that encompass the entire product development lifecycle. From initial concept and design to prototyping, testing, and final implementation, we ensure that every aspect of the project is meticulously managed and executed.',
    headingOne: 'We design and develop power electronic systems',
    descriptionOneShort: 'We design and develop power electronic systems that ensure efficient and reliable power management solutions. Our expertise encompasses everything from power converters to advanced energy systems, catering to diverse industry needs.',
    descriptionOneLong: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    headingTwo: 'Where can I get some?',
    descriptionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    descriptionSectionOneMedia: powerElectronicDetail,
    descriptionSectionOne: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
    descriptionSectionTwoMedia: powerElectronicDetail,
    descriptionSectionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
  }, {
    id: 11,
    icon: ESSandEMITesting,
    servicesBg: ESSandEMITesting,
    bgImage: ESSandEMITesting,
    image: ESSandEMITesting,
    title: 'ESS and EMI Testing',
    description: 'To guarantee the highest levels of reliability and performance, our products undergo rigorous Environmental Stress Screening (ESS) and Electromagnetic Interference (EMI) testing as per MIL-STD standards. This ensures that our solutions can withstand the harshest environments and meet stringent regulatory requirements.',
    headingOne: 'We design and develop power electronic systems',
    descriptionOneShort: 'We design and develop power electronic systems that ensure efficient and reliable power management solutions. Our expertise encompasses everything from power converters to advanced energy systems, catering to diverse industry needs.',
    descriptionOneLong: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    headingTwo: 'Where can I get some?',
    descriptionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    descriptionSectionOneMedia: powerElectronicDetail,
    descriptionSectionOne: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
    descriptionSectionTwoMedia: powerElectronicDetail,
    descriptionSectionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
  }, {
    id: 12,
    icon: ClientAcceptanceTesting,
    servicesBg: ClientAcceptanceTesting,
    bgImage: ClientAcceptanceTesting,
    image: ClientAcceptanceTesting,
    title: 'Client Acceptance Testing',
    description: 'We place a strong emphasis on client satisfaction and quality assurance. Our solutions are subject to thorough acceptance testing at the client site, ensuring that they meet all specified requirements and perform flawlessly in real-world conditions.',
    headingOne: 'We design and develop power electronic systems',
    descriptionOneShort: 'We design and develop power electronic systems that ensure efficient and reliable power management solutions. Our expertise encompasses everything from power converters to advanced energy systems, catering to diverse industry needs.',
    descriptionOneLong: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    headingTwo: 'Where can I get some?',
    descriptionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    descriptionSectionOneMedia: powerElectronicDetail,
    descriptionSectionOne: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
    descriptionSectionTwoMedia: powerElectronicDetail,
    descriptionSectionTwo: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, ",
  }
  // Add more services as needed
];