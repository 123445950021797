import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for validation
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './style.scss';

const InnerPageBanner = ({ backgroundImage, title, centerLink }) => {
  // Intersection Observer hooks
  const { ref: sectionRef, inView: sectionInView } = useInView({
    triggerOnce: false, // Trigger the animation every time the section is in view
    threshold: 0.1, // Adjust this value to change when the animation triggers
  });

  // Animation variants
  const variants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 },
  };

  // Animation transition
  const transition = { duration: 1, ease: 'easeOut' };

  return (
    <section
      className="innerPageBanner-sec bg-theme bg-cover"
      style={{ backgroundImage: `url(${backgroundImage})` }}
      ref={sectionRef}
    >
      <div className="custom-container">
        <div className="row">
          <div className="col-md-12">
            <div className="description mb-0">
              <h1>{title}</h1>
            </div>
          </div>
        </div>
      </div>
      <motion.div
        className="breadcrumb mb-0"
        initial="hidden"
        animate={sectionInView ? "visible" : "hidden"}
        variants={variants}
        transition={{ ...transition, delay: 0.3 }}
      >
        <div className="custom-container">
          <div className="row">
            <div className="col-md-12">
              <div className="text-start">
                <Link className="d-link" to={'/'}>
                  <i className="fa fa-house"></i> <span>Home</span>
                </Link>
                
                {/* Conditionally render the center link based on the centerLink prop */}
                {centerLink && (
                  <>
                    <span className="mx-2"> 
                      <i className="fa fa-angles-right"></i>
                    </span>
                    <Link className="d-link" to={centerLink.path}>
                      <span>{centerLink.label}</span>
                    </Link>
                  </>
                )}
                
                <span className="current">
                  <i className="fa fa-angles-right"></i> {title}
                </span>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

// Add PropTypes for prop validation
InnerPageBanner.propTypes = {
  backgroundImage: PropTypes.string.isRequired, // backgroundImage should be a string
  title: PropTypes.string.isRequired, // title should be a string
  centerLink: PropTypes.shape({
    path: PropTypes.string,  // path should be a string (e.g., /careers)
    label: PropTypes.string, // label should be a string (e.g., Careers)
  }), // centerLink is optional
};

// Default props (in case centerLink is not provided)
InnerPageBanner.defaultProps = {
  centerLink: null, // No center link by default
};

export default InnerPageBanner;
