import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Services } from './ServicesData';
import { PDFIcon } from 'src/assets';
import './style.scss';

const SidebarDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [activeServiceId, setActiveServiceId] = useState(id); // Track active class

  useEffect(() => {
    const service = Services.find(service => service.id === id);
    setSelectedCategory(service);
    setActiveServiceId(id); // Set active class on route change
  }, [id]);

  const handleCategoryClick = (service) => {
    if (service.id !== activeServiceId) {
      setActiveServiceId(service.id); // Update active class first
      setSelectedCategory(service); // Set the selected category
      navigate(`/services/${service.id}`); // Then navigate
    }
  };

  return (
    <div className="sidebar-details-page sticky">
      <nav>
        <ul>
          {Services.map((service) => (
            <li
              key={service.id}
              className={activeServiceId === service.id ? 'active' : ''} // Apply active class
            >
              <button
                className={`nav-list ${activeServiceId === service.id ? 'active' : ''}`}
                onClick={() => handleCategoryClick(service)}
              >
                {service.title}
                <i className="fa fa-angle-right"></i>
              </button>
            </li>
          ))}
        </ul>
      </nav>
      <div className='for-desktop'>
        <div className="contact red-box">
          <p>Contact With Us For any advice</p>
          <i className="fa fa-phone"></i>
          <small>Need help? Talk to an expert</small>
          <a href="tel:+44 20 7123 4567">+44 20 7123 4567</a>
        </div>
        <button className="pdf-download-btn">
          <img className="desktop animate__fadeInUp animate__animated animate__slow" src={PDFIcon} alt="PDF Icon" />
          Download PDF
        </button>
      </div>
      {selectedCategory && (
        <div className="content">
          <h1>{selectedCategory.title}</h1>
          <p>{selectedCategory.description}</p>
        </div>
      )}
    </div>
  );
};

export default SidebarDetailsPage;
