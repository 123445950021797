import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
// import { Typewriter } from 'react-simple-typewriter';
import { BannerBg, BigLogoIcon, DownArrow } from 'src/assets';
import './style.scss';

const AccelerateYourBusiness = () => {
  // Intersection Observer hooks
  const { ref: sectionRef, inView: sectionInView, } = useInView({
    triggerOnce: false, // Trigger the animation every time the section is in view
    threshold: 0.1, // Adjust this value to change when the animation triggers
  });

  // Animation variants
  const variants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 },
  };

  // Animation transition
  const transition = { duration: 1, ease: 'easeOut' };

  return (
    <section className="accelerateYourBusiness-sec" ref={sectionRef}>
      <video autoPlay loop muted className="background-video">
        <source src={BannerBg} type="video/mp4" />
        {/* Add additional source elements for other video formats if needed */}
        Your browser does not support the video tag.
      </video>
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-8 col-md-11">
            <div className="description text-center mb-0">
              {/* Big Logo with Animation */}
              <motion.img
                initial="hidden"
                animate={sectionInView ? "visible" : "hidden"}
                variants={variants}
                transition={{ ...transition, delay: 0.2 }}
                className="img-fluid mb-5"
                src={BigLogoIcon}
                alt="BigLogoIcon"
              />
              {/* Main Heading with Animation */}
              {/* <motion.h1
                initial="hidden"
                animate={sectionInView ? "visible" : "hidden"}
                variants={variants}
                transition={{ ...transition, delay: 0.5 }}
                className="text-white"
              >
                TRAM<span className='text-red'>IQ</span><br /> International Pvt Ltd
              </motion.h1> */}
              {/* Description with Animation */}
              <motion.p
                initial="hidden"
                animate={sectionInView ? "visible" : "hidden"}
                variants={variants}
                transition={{ ...transition, delay: 1.1 }}
                className=''
              >
                Space Times Solutions is at the forefront of technological innovation, dedicated to addressing the complex challenges in the engineering domain. By leveraging cutting-edge research and unparalleled expertise, we transform our customer needs into industry-standard solutions that drive progress and foster sustainable growth of your Company.
              </motion.p>
            </div>
          </div>
        </div>
      </div>
      {/* Contact Info with Animation */}
      {/* <motion.ul
        initial="hidden"
        animate={sectionInView ? "visible" : "hidden"}
        variants={variants}
        transition={{ ...transition, delay: 1.4 }}
        className="contact-info-banner"
      >
        <li>
          <a href="mailto:info@spacetimessolutions.com"><i className="far fa-envelope-open"></i><span>info@spacetimessolutions.com</span></a>
        </li>
        <li>
          <a href="tel:92-21-35300262"><i className="fas fa-phone"></i><span>+44 20 7123 4567</span></a>
        </li>
      </motion.ul> */}
      <a href="#twoSideFullWidthSec" className='float-down-arrow desktop animate__fadeInUp animate__animated animate__slow'>
        {/* <i className="fa fa-mouse"></i> */}
        <img src={DownArrow} alt="Down Arrow" />
        {/* <i className="fa fa-chevron-double-down"></i> */}
      </a>
    </section>
  );
};

export default AccelerateYourBusiness;