import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // Import prop-types
import { motion, useAnimation } from 'framer-motion';
import { Typewriter } from 'react-simple-typewriter';
import { RisingStars, AboutLineImage } from 'src/assets';

import "./style.scss";

const VMVComponent = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState(0);
    const controls = useAnimation();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    controls.start('visible');
                } else {
                    controls.start('hidden');
                }
            },
            { threshold: 0.1 }
        );

        const section = document.querySelector('.ah-vmv-sec');
        if (section) {
            observer.observe(section);
        }

        return () => {
            if (section) {
                observer.unobserve(section);
            }
        };
    }, [controls]);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const handleKeyDown = (event, index) => {
        if (event.key === 'Enter' || event.key === ' ') {
            setActiveTab(index);
        }
    };

    if (!tabs || tabs.length === 0) {
        return <div>No tabs data provided</div>;
    }

    // Animation variants
    const imageVariants = {
        hidden: { opacity: 0, scale: 0.8 },
        visible: { opacity: 1, scale: 1 },
    };

    const descriptionVariants = {
        hidden: { opacity: 0, y: 30 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <motion.section
            className='ah-vmv-sec ah-pt-50'
            initial="hidden"
            animate={controls}
            variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1 }
            }}
            transition={{ duration: 1 }}
        >
            <div className="custom-container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-12">
                        <motion.img
                            src={tabs[activeTab].VMVMedia}
                            className="line1"
                            alt="Animation"
                            variants={imageVariants}
                            initial="hidden"
                            animate={controls}
                            transition={{ duration: 2 }}
                            onError={(e) => { e.target.onerror = null; e.target.src = AboutLineImage; }}
                        />
                    </div>
                    <div className="col-lg-7 col-md-12">
                        <motion.div
                            className="description ah-mb-50"
                            variants={descriptionVariants}
                            initial="hidden"
                            animate={controls}
                            transition={{ duration: 1, delay: 0.5 }}
                        >
                            <img className='animated-badge-right' src={RisingStars} alt="Rising Stars" />
                            <div className="cstm-badge">
                                <img src={RisingStars} alt="Rising Stars" />
                                <span>
                                    <Typewriter
                                        words={[tabs[activeTab].Statement]}
                                        loop={-1}
                                        cursor
                                        cursorStyle='_'
                                        typeSpeed={70}
                                        deleteSpeed={50}
                                        delaySpeed={1000}
                                    />
                                </span>
                            </div>
                            <br />
                            <h2>{tabs[activeTab].Heading}</h2>
                            <p>{tabs[activeTab].Description}</p>
                            {tabs.map((tab, index) => (
                                <motion.div
                                    key={index}
                                    className={`tab ${activeTab === index ? 'active' : ''}`}
                                    onClick={() => handleTabClick(index)}
                                    onKeyDown={(event) => handleKeyDown(event, index)}
                                    role="button"
                                    tabIndex={0}
                                    variants={descriptionVariants}
                                    initial="hidden"
                                    animate={controls}
                                    transition={{ duration: 0.5, delay: 0.7 }}
                                >
                                    {tab.title}
                                </motion.div>
                            ))}
                        </motion.div>
                    </div>
                </div>
            </div>
        </motion.section>
    );
};

// Define prop types
VMVComponent.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        VMVMedia: PropTypes.string.isRequired,
        Statement: PropTypes.string.isRequired,
        Heading: PropTypes.string.isRequired,
        Description: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
    })).isRequired
};

export default VMVComponent;
