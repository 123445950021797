import React, { useState, useEffect } from "react";
import {
  BusinessIdeas,
  Companybusiness,
  Freshers,
  AboutPageBg,
  RisingStars,
  Checked,
} from "src/assets";
import { Modal } from "react-bootstrap";
import { Button, Typography } from "../../Base";
import emailjs from "emailjs-com";
import Select from "react-select";
import "./style.scss";

emailjs.init("jPAZ-WSmDjXn96NUr");

const Opportunity = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    companyName: "", // For The Business Corporation
    roleInCompany: "", // For The Business Corporation
    contact: "", // For The Business Corporation, Internship, Naval Ideas
    education: "", // For Internship, Naval Ideas
    email: "",
    message: "",
    sendCopy: false,
  });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const data = [
    {
      id: 1,
      title: "The Business Corporation",
      description: "Explore tailored solutions to scale and optimize your company.",
      image: Companybusiness,
      buttonText: "Get Started",
    },
    {
      id: 2,
      title: "Internship",
      description: "Kickstart your career with our dedicated programs for freshers.",
      image: Freshers,
      buttonText: "Join Now",
    },
    {
      id: 3,
      title: "Naval Ideas",
      description: "Transform your naval idea into a reality with expert guidance.",
      image: BusinessIdeas,
      buttonText: "Submit Idea",
    },
  ];

  const handleShowModal = (category) => {
    setSelectedCategory(category);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal) {
      setFormData({
        name: "",
        companyName: "",
        roleInCompany: "",
        contact: "",
        education: "",
        email: "",
        message: "",
        sendCopy: false,
      });
      setErrors({});
      setIsSubmitted(false);
    }
  }, [showModal]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      education: selectedOption,
    }));
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.name.trim()) {
      errors.name = "Name is required";
    }
    if (!data.contact.trim()) {
      errors.contact = "Contact number is required";
    }
    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email address is invalid";
    }
    if (!data.message.trim()) {
      errors.message = "Message is required";
    }

    // Add validation for category-specific fields
    if (selectedCategory === "The Business Corporation") {
      if (!data.companyName.trim()) {
        errors.companyName = "Company Name is required";
      }
      if (!data.roleInCompany.trim()) {
        errors.roleInCompany = "Your Role in the Company is required";
      }
    }
    if (selectedCategory === "Internship" && !data.education) {
      errors.education = "Education is required";
    }
    if (selectedCategory === "Naval Ideas" && !data.education) {
      errors.education = "Education is required";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitted(true);
      emailjs
        .sendForm(
          "service_tsp8aka",
          "template_8a2enty",
          e.target,
          "jPAZ-WSmDjXn96NUr"
        )
        .then(
          (result) => {
            console.log(result.text);
            setFormData({
              name: "",
              companyName: "",
              roleInCompany: "",
              contact: "",
              education: "",
              email: "",
              message: "",
              sendCopy: false,
            });
          },
          (error) => {
            console.error("Error sending email:", error.text);
            alert("Error sending message. Please try again later.");
          }
        );
    } else {
      setErrors(validationErrors);
    }
  };

  const educationOptions = [
    { value: "High School", label: "High School" },
    { value: "Bachelor's Degree", label: "Bachelor's Degree" },
    { value: "Master's Degree", label: "Master's Degree" },
    { value: "PhD", label: "PhD" },
  ];

  return (
    <section
      className="opportunity-sec"
      style={{ backgroundImage: `url(${AboutPageBg})` }}
    >
      <div className="container">
        <div className="row">
          <div className="description text-center mb-5">
            <div className="cstm-badge">
              <img src={RisingStars} alt="RisingStars" />
              <span>Opportunities</span>
            </div>
            <br></br>
            <h2>Let's Grow Each Other</h2>
            <p>
              This section provides resources and opportunities for businesses,
              freshers, and entrepreneurs.
              <br />
              Select a category to explore more and connect with us.
            </p>
          </div>
          {data.map((item) => (
            <div key={item.id} className="col-md-4">
              <div className="card mb-4">
                <img
                  src={item.image}
                  className="card-img-top"
                  alt={item.title}
                />
                <div className="card-body">
                  <h4 className="card-title">{item.title}</h4>
                  <p className="card-text">{item.description}</p>
                  <Button
                    className="btn d-btn"
                    color="primary"
                    onClick={() => handleShowModal(item.title)}
                  >
                    <Typography variant="button-text">
                      {item.buttonText}
                    </Typography>
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>

        <Modal
          className="request-modal"
          show={showModal}
          onHide={handleCloseModal}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <h4 className="m-0">{selectedCategory}</h4>
          </Modal.Header>
          <Modal.Body>
            {!isSubmitted ? (
              <form onSubmit={handleSubmit}>
                {/* Conditionally render fields based on selected category */}
                <div className="form-group">
                  <label htmlFor="name">
                    Your Name<sup>*</sup>:
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="form-control"
                  />
                  {errors.name && (
                    <span className="text-danger">{errors.name}</span>
                  )}
                </div>
                {selectedCategory === "The Business Corporation" && (
                  <>
                    <div className="form-group">
                      <label htmlFor="companyName">
                        Company Name<sup>*</sup>:
                      </label>
                      <input
                        type="text"
                        id="companyName"
                        name="companyName"
                        value={formData.companyName}
                        onChange={handleChange}
                        className="form-control"
                      />
                      {errors.companyName && (
                        <span className="text-danger">
                          {errors.companyName}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="roleInCompany">
                        Your Role in the Company<sup>*</sup>:
                      </label>
                      <input
                        type="text"
                        id="roleInCompany"
                        name="roleInCompany"
                        value={formData.roleInCompany}
                        onChange={handleChange}
                        className="form-control"
                      />
                      {errors.roleInCompany && (
                        <span className="text-danger">
                          {errors.roleInCompany}
                        </span>
                      )}
                    </div>
                  </>
                )}
                {(selectedCategory === "Internship" ||
                  selectedCategory === "Naval Ideas") && (
                  <>
                    <div className="form-group">
                      <label htmlFor="education">
                        Education<sup>*</sup>:
                      </label>
                      <Select
                        id="education"
                        name="education"
                        value={formData.education}
                        onChange={handleSelectChange}
                        options={educationOptions}
                        className="form-control"
                        classNamePrefix="react-select"
                      />
                      {errors.education && (
                        <span className="text-danger">{errors.education}</span>
                      )}
                    </div>
                  </>
                )}
                <div className="form-group">
                  <label htmlFor="contact">
                    Contact Number<sup>*</sup>:
                  </label>
                  <input
                    type="text"
                    id="contact"
                    name="contact"
                    value={formData.contact}
                    onChange={handleChange}
                    className="form-control"
                  />
                  {errors.contact && (
                    <span className="text-danger">{errors.contact}</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="email">
                    Email Address<sup>*</sup>:
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="form-control"
                  />
                  {errors.email && (
                    <span className="text-danger">{errors.email}</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="message">
                    Message<sup>*</sup>:
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="form-control"
                  />
                  {errors.message && (
                    <span className="text-danger">{errors.message}</span>
                  )}
                </div>
                {/* <div className="form-group form-check">
                  <input
                    type="checkbox"
                    id="sendCopy"
                    name="sendCopy"
                    checked={formData.sendCopy}
                    onChange={handleChange}
                    className="form-check-input"
                  />
                  <label htmlFor="sendCopy" className="form-check-label">
                    Send me a copy of this message
                  </label>
                </div> */}
                <div className="form-group text-right">
                  <Button type="submit" color="primary">
                    <Typography variant="button-text">Submit</Typography>
                  </Button>
                </div>
              </form>
            ) : (
              <div className="form-submitted text-center">
                <img
                  src={Checked}
                  alt="checked"
                  className="checked-icon"
                />
                <h4 className="mb-3">Thank You!</h4>
                <p>
                  Your message has been submitted successfully.
                  <br />
                  We'll get back to you shortly.
                </p>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </section>
  );
};

export default Opportunity;
