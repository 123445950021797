import React from 'react';
// import { Link } from 'react-router-dom';
// import { CustomerSupport, LogoDesigns, ResponsiveWebDesign, BusinessConsulting, SearchEngineOptimization } from 'src/assets';
// import { Typography, Button } from 'src/components/common/Base';
import { InnerPageBanner, Partner } from 'src/components/common/Widgets';
import ServicesListDefault from 'src/components/common/Widgets/ServiceDetails/index';
import { GroupImage } from 'src/assets';
import './style.scss';

const ServicesPage = () => {

    const backgroundImageUrl = GroupImage;
    const pageTitle = 'Product & Services';

    return (
        <>
            <div className='services-page'>
                <InnerPageBanner backgroundImage={backgroundImageUrl} title={pageTitle} />
                <ServicesListDefault />
                <Partner />
            </div>
        </>
    );
};

export default ServicesPage;