import React from 'react';
import { motion } from 'framer-motion';
// import { RisingStars, BgDotsBottom, BgDotsAboutPage } from 'src/assets';
import { RisingStars } from 'src/assets';
import { useInView } from 'react-intersection-observer';
import { Typewriter } from 'react-simple-typewriter';

import './style.scss';

const OurTeam = () => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const animationVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const teamMembers = [
    {
      title: 'Experienced Engineers from the Defense Sector',
      description: 'Our team includes engineers with extensive experience in the defense sector, bringing a wealth of knowledge and expertise to our projects.',
      // link: '#',
    },
    {
      title: 'Seasoned Design Engineers from the Commercial Industry',
      description: 'We have seasoned design engineers who have honed their skills in the commercial mechanical and electronic industries. Their deep understanding of commercial design',
      // link: '#',
    },
    {
      title: 'Matured Project Managers',
      description: 'Our project managers are highly experienced professionals who excel in planning, executing, and overseeing complex projects. Their ability to manage resources',
      // link: '#',
    },
    {
      title: 'Ambitious Fresh Graduates',
      description: 'We believe in nurturing the next generation of engineers and innovators. Our team includes ambitious fresh graduates who bring new perspectives and enthusiasm to our projects.',
      // link: '#',
    },
  ];

  return (
    <section className='ourTeam-sec' id='ourTeam-sec'>
      {/* <div className='bg'>
        <img className='bg-1' src={BgDotsAboutPage} alt="Background" />
        <img className='bg-2' src={BgDotsBottom} alt="Background" />
      </div> */}
      <div className="custom-container">
        <div className="row">
          <div className="col-12">
            <motion.div
              className="cstm-badge"
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={animationVariants}
              transition={{ duration: 0.6 }}
            >
              <img src={RisingStars} alt="RisingStars" />
              <span>
                <Typewriter
                  words={['Our Team']}
                  loop={-1}
                  cursor
                  cursorStyle='_'
                  typeSpeed={70}
                  deleteSpeed={50}
                  delaySpeed={1000}
                />
              </span>
            </motion.div>
            <br />

            <motion.div
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={animationVariants}
              transition={{ duration: 0.6 }}
              ref={ref}
            >
              <h2 className='theme-h-color-black'>At Space Times Solutions, we pride ourselves on having a diverse and highly skilled team</h2>
              <p className='text-dark ah-mb-50'>That is the backbone of our success. Our team is composed of experienced engineers, seasoned design professionals, accomplished project managers, and ambitious fresh graduates, all working together to drive innovation and excellence.</p>
            </motion.div>
          </div>
          {teamMembers.map((member, index) => (
            <div key={index} className="col-xl-3 col-lg-4 col-md-6">
              <div className='team-member'>
                <h3>{member.title}</h3>
                <p>{member.description}</p>
                {/* <a href={member.link}>Explore Our Team</a> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
