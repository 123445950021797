import React from 'react';
import { Link } from 'react-router-dom';
import { RisingStars, OurMissionIcon } from 'src/assets';
import { Typography, Button, SlickSlider } from 'src/components/common/Base';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { Typewriter } from 'react-simple-typewriter';
import './style.scss';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

  const TwoSideFullWidthSection = ({ twoSideSecLeftSideImg, twoSideSecBadge, twoSideSecTitle, twoSideSecDescription }) => {
  const { ref: leftImgRef, inView: leftImgInView } = useInView({ triggerOnce: false, threshold: 0.1 });
  const { ref: badgeRef, inView: badgeInView } = useInView({ triggerOnce: false, threshold: 0.1 });
  const { ref: titleRef, inView: titleInView } = useInView({ triggerOnce: false, threshold: 0.1 });
  const { ref: descriptionRef, inView: descriptionInView } = useInView({ triggerOnce: false, threshold: 0.1 });
  const { ref: buttonRef, inView: buttonInView } = useInView({ triggerOnce: false, threshold: 0.1 });
  const { ref: sliderRef, inView: sliderInView } = useInView({ triggerOnce: false, threshold: 0.1 });

  return (
    <section className="twoSideFullWidthSec bg-cover" id='twoSideFullWidthSec'>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-12 col-lg-5 cstm-column">
            <div className="inner-box mb-5">
              <div>
                <motion.div
                  ref={badgeRef}
                  initial={{ opacity: 0, x: -50 }}
                  animate={badgeInView ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }}
                  transition={{ duration: 0.6 }}
                  className="cstm-badge"
                >
                  <img src={RisingStars} alt="RisingStars" />
                  <span>
                    <Typewriter
                      words={[twoSideSecBadge]}
                      loop={-1}
                      cursor
                      cursorStyle='_'
                      typeSpeed={70}
                      deleteSpeed={50}
                      delaySpeed={1000}
                    />
                  </span>
                </motion.div>
                <br />
                <motion.h2
                  ref={titleRef}
                  initial={{ opacity: 0, x: -50 }}
                  animate={titleInView ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }}
                  transition={{ duration: 0.6, delay: 0.2 }}
                  className=''
                >
                  {twoSideSecTitle}
                </motion.h2>
                <motion.p
                  ref={descriptionRef}
                  initial={{ opacity: 0, x: -50 }}
                  animate={descriptionInView ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                  className='mb-4'
                  dangerouslySetInnerHTML={{ __html: twoSideSecDescription }}
                />
                <motion.div
                  ref={buttonRef}
                  initial={{ opacity: 0, y: 50 }}
                  animate={buttonInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                  transition={{ duration: 0.6, delay: 0.6 }}
                  className='m-auto'
                >
                  {/* <Button className='btn primary' color={'link'} variant={'solid'}>
                    <Typography variant="button-text">See More</Typography>
                  </Button> */}
                  <Link to="/About-us">
                    <Button className="btn d-btn" color="primary">
                      <Typography variant="button-text">See More</Typography>
                    </Button>
                  </Link>
                </motion.div>
              </div>
            </div>
          </div>
          <div className="col-md-6 cstm-column">
            <div className="inner-box left-inner-box mb-0">
              <motion.div
                ref={leftImgRef}
                initial={{ opacity: 0, y: 50 }}
                animate={leftImgInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                transition={{ duration: 0.6 }}
              >
                <img src={twoSideSecLeftSideImg} alt={twoSideSecLeftSideImg} style={{ width: '100%', height: 'auto' }} />
              </motion.div>
              <motion.div
                ref={sliderRef}
                initial={{ opacity: 0, y: 50 }}
                animate={sliderInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                transition={{ duration: 0.6, delay: 0.2 }}
                className='mission-vission-sec'
              >
                <SlickSlider settings={settings}>
                  <div className="slide-content about-us-sec-slider">
                    <div className="icon"><img src={OurMissionIcon} alt="OurMissionIcon" /></div>
                    <h3>Our Mission</h3>
                    <p>Our mission is "to leverage cutting-edge research and expertise to address the pressing technological challenges.</p>
                  </div>
                  <div className="slide-content about-us-sec-slider">
                    <div className="icon"><img src={OurMissionIcon} alt="OurMissionIcon" /></div>
                    <h3>Our Vision</h3>
                    <p>Our mission is "to leverage cutting-edge research and expertise to address the pressing technological challenges.</p>
                  </div>
                </SlickSlider>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TwoSideFullWidthSection;
