import React, { useState } from 'react';
import { RisingStars, OurCustomer } from 'src/assets';
import { Accordion } from 'src/components/common/Base';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { Typewriter } from 'react-simple-typewriter';

import './style.scss';

const OurCustomerDefault = () => {
	const [openAccordionIndex, setOpenAccordionIndex] = useState(0); // Set the default open accordion

	const { ref: sectionRef, inView: sectionInView } = useInView({
		triggerOnce: false,
		threshold: 0.1,
	});

	// const { ref: imageRef, inView: imageInView } = useInView({
	// 	triggerOnce: false,
	// 	threshold: 0.1,
	// });

	const accordions = [
		{
			title: '01 Defense Sector',
			content: 'Our expertise is particularly focused on naval and land applications within the defense sector. We provide cutting-edge engineering solutions that enhance operational efficiency, reliability, and performance. By offering tailored solutions that are rigorously tested for environmental stress and electromagnetic interference, we ensure that our products meet the stringent standards required by military operations.'
		},
		{
			title: '02 Special Purpose Mechanical and Electronic Industries',
			content: 'Our expertise is particularly focused on naval and land applications within the defense sector. We provide cutting-edge engineering solutions that enhance operational efficiency, reliability, and performance. By offering tailored solutions that are rigorously tested for environmental stress and electromagnetic interference, we ensure that our products meet the stringent standards required by military operations.'
		},
		{
			title: '03 Unique Selling Proposition',
			content: 'Our expertise is particularly focused on naval and land applications within the defense sector. We provide cutting-edge engineering solutions that enhance operational efficiency, reliability, and performance. By offering tailored solutions that are rigorously tested for environmental stress and electromagnetic interference, we ensure that our products meet the stringent standards required by military operations.'
		}
	];

	return (
		<>
			<section className='paddingY-100 our-customer-sec'>
				<div className="container">
					<div className="row justify-content-center align-items-center">
						<div className="col-md-12 col-lg-7">
							<motion.div
								ref={sectionRef}
								initial={{ opacity: 0, x: -50 }}
								animate={sectionInView ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }}
								transition={{ duration: 0.6 }}
							>
								<div className="description">
									<div className="cstm-badge">
										<img src={RisingStars} alt="RisingStars" />
										<span>
											<Typewriter
												words={['Our Customer',]}
												loop={-1}
												cursor
												cursorStyle='_'
												typeSpeed={70}
												deleteSpeed={50}
												delaySpeed={1000}
											/>
										</span>
									</div>
									<div className="cstm-badge"></div>
									<br></br>
									<h2>At Space Times Solutions, our primary customers are in the defense sector</h2>
									<p>The special purpose mechanical, and electronic industries. Our unique approach and deep understanding of these sectors enable us to deliver customized, cost-effective solutions that meet the specific needs and challenges of our clients. The defense sector is experiencing increased demand for advanced technological solutions that enhance operational capabilities and ensure mission success. By focusing on these trends and leveraging our expertise in providing customized, cost-effective solutions, Space Times Solutions is well-positioned to meet the evolving needs of our valued customer and drive sustained growth and success.</p>

									<div className="res-footer1">
										{accordions.map((accordion, index) => (
											<Accordion
												key={index}
												title={accordion.title}
												content={accordion.content}
												isOpen={openAccordionIndex === index}
												onClick={() => setOpenAccordionIndex(openAccordionIndex === index ? -1 : index)}
											/>
										))}
									</div>
								</div>
							</motion.div>
						</div>
						<div className="col-md-12 col-lg-5">
							{/* <motion.div
								ref={imageRef}
								initial={{ opacity: 0, x: 50 }}
								animate={imageInView ? { opacity: 1, x: 0 } : { opacity: 0, x: 50 }}
								transition={{ duration: 0.6 }}
							> */}
								<div className='right-image'>
									<img src={OurCustomer} alt="OurCustomer" />
								</div>
							{/* </motion.div> */}
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default OurCustomerDefault;
