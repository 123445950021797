import { Link, useLocation } from 'react-router-dom';
import { Services } from './ServicesData';
import { RisingStars, ServiceBg } from 'src/assets';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { Typewriter } from 'react-simple-typewriter';
import Slider from 'react-slick';
import './style.scss';

const ServicesListDefault = () => {
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    const location = useLocation(); // Get the current route

    // Slick slider settings
    const sliderSettings = {
        dots: true,
        arrows: false,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024, 
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    speed: 200,
                },
            },
            {
                breakpoint: 768, 
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    speed: 200,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 200,
                },
            },
        ],
    };

    return (
        <section className="services-sec" style={{ backgroundImage: `url(${ServiceBg})` }}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="description">
                            <motion.div
                                ref={ref}
                                initial={{ opacity: 0, y: 50 }}
                                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                                transition={{ duration: 0.6 }}
                            >
                                <div className="cstm-badge">
                                    <img src={RisingStars} alt="RisingStars" />
                                    <span>Our PRODUCTS<Typewriter
                                        words={[' AND SERVICES',]}
                                        loop={-1}
                                        cursor
                                        cursorStyle='_'
                                        typeSpeed={70}
                                        deleteSpeed={50}
                                        delaySpeed={1000}
                                    /></span>
                                </div>
                                <br />
                                <h2 className='text-white'>
                                    At Space Times Solutions, we specialize in providing bespoke technology-centric{' '}
                                    <span>Cloud-Based engineering solutions tailored to meet the unique requirements of our clients.</span>
                                </h2>
                                <p className='text-white'>
                                    Our expertise spans a wide range of advanced technology areas, enabling us to deliver comprehensive solutions that drive innovation and excellence.
                                </p>
                            </motion.div>
                        </div>
                    </div>

                    <div className="w-100"></div>

                    {/* Conditional rendering based on the route */}
                    {location.pathname === '/Services' ? (
                        <div className="row">
                            {Services.map((service) => (
                                <div key={service.id} className="col-md-4">
                                    <div className="service-box-main">
                                        <h3>{service.title}</h3>
                                        <div className="service-box" style={{ backgroundImage: `url(${service.servicesBg})` }}>
                                            <div className="icon">
                                                <img
                                                    style={{ width: '55px', height: '55px' }}
                                                    src={service.icon}
                                                    alt={service.title}
                                                />
                                            </div>
                                            <p>
                                                <span>{service.description}</span>
                                                <Link className="d-link" to={`/Services/${service.id}`}>Read more <i>&#8594;</i></Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <Slider {...sliderSettings}>
                            {Services.map((service, index) => (
                                <div key={service.id} className={`service-area service-${index}`}>
                                    <div className="service-box-main">
                                        <h3>{service.title}</h3>
                                        <div className="service-box" style={{ backgroundImage: `url(${service.servicesBg})` }}>
                                            <div className="icon">
                                                <img
                                                    style={{ width: '55px', height: '55px' }}
                                                    src={service.icon}
                                                    alt={service.title}
                                                />
                                            </div>
                                            <p>
                                                <span>{service.description}</span>
                                                <Link className="d-link" to={`/Services/${service.id}`}>Read more <i>&#8594;</i></Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    )}
                </div>
            </div>
        </section>
    );
};

export default ServicesListDefault;
