import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Button, Typography } from 'src/components/common/Base';
import { Checked, GroupImage } from 'src/assets';
import { InnerPageBanner } from 'src/components/common/Widgets';
import './style.scss';
import { Link } from 'react-router-dom';

// Set up EmailJS with your user ID and template ID
emailjs.init('jPAZ-WSmDjXn96NUr');

const ContactUsDefault = () => {
	const [formData, setFormData] = useState({
		name: '',
		subject: '',
		companyName: '',
		number: '',
		email: '',
		message: ''
	});
	const [errors, setErrors] = useState({});
	const [isSubmitted, setIsSubmitted] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const errors = validateForm(formData);
		if (Object.keys(errors).length === 0) {
			// eslint-disable-next-line no-console
			console.log(formData);
			// Handle the error in the UI
			setIsSubmitted(true);

			emailjs.sendForm('service_tsp8aka', 'template_8a2enty', e.target, 'jPAZ-WSmDjXn96NUr')
				.then((result) => {
					// eslint-disable-next-line no-console
					console.log(result.text);
					// Handle the error in the UI
					setFormData({ name: '', subject: '', companyName: '', number: '', email: '', message: '' });
				}, (error) => {
					// eslint-disable-next-line no-console
					console.error('Error sending email:', error.text);
					// Handle the error in the UI
					alert('Error sending message. Please try again later.');
				});

		} else {
			setErrors(errors);
		}
	};

	const validateForm = (data) => {
		const errors = {};
		if (!data.name.trim()) {
			errors.name = 'Name is required';
		}
		if (!data.subject.trim()) {
			errors.subject = 'Subject is required';
		}
		if (!data.companyName.trim()) {
			errors.companyName = 'Company Name is required';
		}
		if (!data.number.trim()) {
			errors.number = 'Number is required';
		}
		if (!data.email.trim()) {
			errors.email = 'Email is required';
		} else if (!/\S+@\S+\.\S+/.test(data.email)) {
			errors.email = 'Email address is invalid';
		}
		if (!data.message.trim()) {
			errors.message = 'Message is required';
		}
		return errors;
	};

	const pageTitle = 'Contact Us';
	const backgroundImageUrl = GroupImage;
	return (
		<>
			<div>
				<div>
					<InnerPageBanner backgroundImage={backgroundImageUrl} title={pageTitle} />
				</div>

				{/* <div>
					<LaunchYouBusiness />
				</div> */}

				<section className='contact-us-main-sec'>
					<div className='container'>
						<div className='row'>
							<div className='col-md-12'>
								<div className="contact-section">
									<div className="contact-info">
										<h2>Contact Information</h2>
										<p>Say something to start a live chat!</p>
										<ul>
											<li>
												<i className="fas fa-phone"></i> <a href='tel:+44 20 7123 4567'>+44 20 7123 4567</a>
											</li>
											<li><i className="fas fa-envelope"></i><a href='mailto:info@spacetimessolutions.com'>info@spacetimessolutions.com</a></li>
											<li><i className="fas fa-map-marker-alt"></i>58a Victoria Road, Romford, United Kingdom, RM1 2JH</li>
										</ul>
										<div className="social-icons">
											<a href="/"><i className="fab fa-twitter"></i></a>
											<a href="/"><i className="fab fa-youtube"></i></a>
											<a href="/"><i className="fab fa-discord"></i></a>
										</div>
									</div>
									{/* Form Start */}
									{!isSubmitted ? (
										<form onSubmit={handleSubmit} className='contact-form'>
											<div className="row">
												<div className="col-lg-6">
													<div className='form-group'>
														<label htmlFor="name">Name<sup>*</sup>:</label>
														<input
															type="text"
															id="name"
															name="name"
															value={formData.name}
															onChange={handleChange}
														/>
														{errors.name && <span className='for-error'>{errors.name}</span>}
													</div>
												</div>
												<div className="col-lg-6">
													<div className='form-group'>
														<label htmlFor="subject">Subject<sup>*</sup>:</label>
														<input
															type="text"
															id="subject"
															name="subject"
															value={formData.subject}
															onChange={handleChange}
														/>
														{errors.subject && <span className='for-error'>{errors.subject}</span>}
													</div>
												</div>
												<div className="col-lg-6">
													<div className='form-group'>
														<label htmlFor="companyName">Company Name<sup>*</sup>:</label>
														<input
															type="text"
															id="companyName"
															name="companyName"
															value={formData.companyName}
															onChange={handleChange}
														/>
														{errors.companyName && <span className='for-error'>{errors.companyName}</span>}
													</div>
												</div>
												<div className="col-lg-6">
													<div className='form-group'>
														<label htmlFor="number">Contact Number<sup>*</sup>:</label>
														<input
															type="number"
															id="number"
															name="number"
															value={formData.number}
															onChange={handleChange}
														/>
														{errors.number && <span className='for-error'>{errors.number}</span>}
													</div>
												</div>
												<div className="col-md-12">
													<div className='form-group'>
														<label htmlFor="email">Email<sup>*</sup>:</label>
														<input
															type="email"
															id="email"
															name="email"
															value={formData.email}
															onChange={handleChange}
														/>
														{errors.email && <span className='for-error'>{errors.email}</span>}
													</div>
												</div>
												<div className="col-md-12">
													<div className='form-group'>
														<label htmlFor="message">Message<sup>*</sup>:</label>
														<textarea
															className='full-height'
															id="message"
															name="message"
															value={formData.message}
															onChange={handleChange}
														/>
														{errors.message && <span className='for-error'>{errors.message}</span>}
													</div>
												</div>
												<div className="col-md-12">
													{/* <button type="submit">Submit</button> */}
													<div className="form-group text-end">
														<Button className={`btn primary hovered desktop animate__fadeInUp animate__animated animate__slow`} color={'link'} variant={'solid'}>
															<Typography variant="button-text">Send Message</Typography>
														</Button>
													</div>
												</div>
											</div>
										</form>
									) : (
										<div className='contact-form text-center afterSubmission '>
											<img className={`desktop animate__fadeInUp animate__animated animate__slow`} style={{ width: '200px', height: 'auto' }} src={Checked} alt="Checked" />
											<br></br>
											<h3>Thank you for your <span>Message!</span></h3>
											<Link to="/">
												<Button className="btn d-btn" color="primary">
													<Typography variant="button-text">Back to Home</Typography>
												</Button>
											</Link>
										</div>
									)}
									{/* Form End*/}
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* <AddressData /> */}
				<section className='map-sec'>
					<iframe 
						title="Space Times Solutions ltd."
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1104.5593112946772!2d0.18545976207896359!3d51.574617499824846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a4cb0fa9dff1%3A0x60414b55c9db8442!2s58A%20Victoria%20Rd%2C%20Romford%20RM1%202JH%2C%20UK!5e0!3m2!1sen!2s!4v1729228511745!5m2!1sen!2s" 
						width="100%" 
						height="495" 
						style={{ border: '0' }}
						allowFullScreen 
						loading="lazy" 
						referrerPolicy="no-referrer-when-downgrade">
					</iframe>
				</section>
			</div>
		</>
	);
};

export default ContactUsDefault;
