import React from 'react';
import { useParams } from 'react-router-dom';
import { Services } from './ServicesData';
import InnerPageBanner from '../InnerPageBanner';
// import { Typewriter } from 'react-simple-typewriter';
import { GroupImage, PDFIcon } from 'src/assets';
import SidebarDetailsPage from './SidebarDetailsPage';
import './style.scss';

const ServiceDetailsDefault = () => {
  const { id } = useParams();
  const service = Services.find(service => service.id === parseInt(id));

  if (!service) {
    return <div><InnerPageBanner title={'Service Not Found'} /></div>;
  }

  const backgroundImageUrl = GroupImage;
  // const pageTitle = 'About Us';
  const pageTitle = service.title;

  return (
    <>
      <div className='single-detail-page'>
        {/* Banner Section */}
        {/* <InnerPageBanner backgroundImage={backgroundImageUrl} title={pageTitle} /> */}
        <InnerPageBanner
          backgroundImage={backgroundImageUrl}
          title={pageTitle}
          centerLink={{ path: '/Services', label: 'Service' }}
        />
        {/* Banner Section */}

        <div className="custom-container">
          <section className='description-body'>
            <SidebarDetailsPage />
            <div className="main-content non-sticky">
              <div className='main-image-box'>
                <img className={`desktop animate__fadeInUp animate__animated animate__slow`} src={service.image} alt={service.title} />
              </div>
              <h2>{service.headingOne}</h2>
              <p>{service.descriptionOneShort}</p>
              <p>{service.descriptionOneLong}</p>
              <h2>{service.headingTwo}</h2>
              <p>{service.descriptionTwo}</p>
              <div className='detailed-inner-sec'>
                <div className='container p-0'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='sub-image-box'>
                        <img className={`desktop animate__fadeInUp animate__animated animate__slow`} src={service.descriptionSectionOneMedia} alt={service.title} />
                      </div>
                      <p>{service.descriptionSectionOne}</p>
                    </div>
                    <div className='col-md-6'>
                      <div className='sub-image-box'>
                        <img className={`desktop animate__fadeInUp animate__animated animate__slow`} src={service.descriptionSectionTwoMedia} alt={service.title} />
                      </div>
                      <p>{service.descriptionSectionTwo}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='for-mbl'>
                <div className='description-body m-0'>
                  <div className='sidebar-details-page'>
                    <div className='contact'>
                      <div className="contact red-box">
                        <p>Contact With Us For any advice</p>
                        <i className="fa fa-phone"></i>
                        <small>Need help? Talk to an expert</small>
                        <a href="tel:+44 20 7123 4567">+44 20 7123 4567</a>
                      </div>
                      <button className="pdf-download-btn">
                        <img className="desktop animate__fadeInUp animate__animated animate__slow" src={PDFIcon} alt="PDF Icon" />
                        Download PDF
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* Page Content Section */}
        {/* <section className="services-sec pb-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="description">
                  <div className="cstm-badge"><img src={RisingStars} alt="RisingStars" />
                    <span>
                      <Typewriter
                        words={['Service',]}
                        loop={-1}
                        cursor
                        cursorStyle='_'
                        typeSpeed={70}
                        deleteSpeed={50}
                        delaySpeed={1000}
                      />
                    </span>
                  </div>
                  <br />
                  <h2>{service.headingOne}</h2>
                  <p>{service.descriptionOneShort}</p>
                  <p>{service.descriptionOneLong}</p>
                </div>
              </div>
              <div className='w-100'></div>
            </div>
          </div>
        </section> */}
        {/* Page Content Section */}
        {/* <section className="twoSideFullWidthSec for-services-details bg-cover">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 sticky">
                <div className="inner-box left-inner-box mb-0 p-0">
                  <div className='w-100'>
                    <img className={`desktop animate__fadeInUp animate__animated animate__slow`} style={{ width: '100%', height: 'auto' }} src={service.image} alt={service.title} />
                  </div>
                </div>
              </div>
              <div className="col-md-6 non-sticky">
                <div className="inner-box mb-0">
                  <div>
                    <div className='description'>
                      {/* <div className="cstm-badge"><img src={RisingStars} alt="RisingStars" /><span>
                        <Typewriter
                          words={['Service',]}
                          loop={-1}
                          cursor
                          cursorStyle='_'
                          typeSpeed={70}
                          deleteSpeed={50}
                          delaySpeed={1000}
                        />
                      </span></div> */}
        {/* <br /> */}
        {/* <h2>{service.serviceInnerSecTitle}</h2>
                      <p className='mb-5'>{service.serviceInnerSecDescription}</p>
                      {/* <h4>Question</h4>
                    <p className='ah-mb-50'>Answer</p> */}
        {/* <ol className='questions-list'>
                        {service.serviceDetailsSecArray.map((serviceDetailsSecArray, index) => (
                          <li key={index}>
                            <div className='questions-title'>{serviceDetailsSecArray.title}</div>
                            {/* <p>{serviceDetailsSecArray.description}</p> */}
        {/*                            <p dangerouslySetInnerHTML={{ __html: serviceDetailsSecArray.description }}></p>

                            {serviceDetailsSecArray.serviceDetailsSecArraySubDetails && (
                              <ul className='sub-ul'>
                                {serviceDetailsSecArray.serviceDetailsSecArraySubDetails.map((serviceDetailsSecArraySubDetails, childIndex) => (
                                  <li key={childIndex}>
                                    <strong>{serviceDetailsSecArraySubDetails.question}</strong>
                                    <p dangerouslySetInnerHTML={{ __html: serviceDetailsSecArraySubDetails.answer }}></p>
                                  </li>
                                ))
                                }
                              </ul>
                            )}
                          </li>
                        ))}
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    </>
  );
};

export default ServiceDetailsDefault;