import LandingPage from 'src/pages/landing-page';
import AboutUs from 'src/pages/about-us';
import ContactUs from 'src/pages/contact-us';
import ServiceDetails from 'src/pages/service-details';
import ServicesPage from 'src/pages/services-page';


export const routes = {
	landingPage: {
		path: '/',
		label: 'Landing Page',
		component: <LandingPage />,
		private: false
	},
	AboutUs: {
		path: '/about-us',
		label: 'About Us',
		component: <AboutUs />,
		private: false
	},
	ServicesPage: {
		path: '/services',
		label: 'Services',
		component: <ServicesPage />,
		private: false
	},
	ServiceDetails: {
		path: '/services/:id',
		label: 'Service Details',
		component: <ServiceDetails />,
		private: false
	},
	contactUs: {
		path: '/contact-us',
		label: 'Contact Us',
		component: <ContactUs />,
		private: false
	}
};

const routesData = {
	defaultRoutes: [],
	protectedRoutes: [],
	// publicRoutes: [routes.comingSoon, routes.landingPage, routes.AboutUs, routes.careers, routes.subscriptionConfirmation, routes.commonQuestions, routes.customers, routes.superProducers, routes.seeStories, routes.whatsNew, routes.ServicesPage ,routes.ServicesList, routes.ServiceDetails, routes.contactUs]
	publicRoutes: [routes.landingPage, routes.AboutUs, routes.ServicesPage, routes.ServiceDetails, routes.contactUs]
};

export default routesData;